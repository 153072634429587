import jQuery from "jquery";

export default function BackToTop() {
    const backToTopElement = jQuery(`<a class="back-to-top"><i class="arrow"></i><i class="arrow"></i></a>`)
        .appendTo('body')
        .on('click', (event) =>
            jQuery('html, body').animate({scrollTop: 0}, '300') && event.preventDefault()
        );

    jQuery(window).on('scroll', ()=> jQuery(window).scrollTop() > 300 ? backToTopElement.addClass('show') : backToTopElement.removeClass('show'));
}

