import jQuery from "jquery";

const store = {}

class Elements {
    /**
     * @param query
     * @return {HTMLElement|jQuery}
     */
    load(query) {
        if (!store[query]) {
            store[query] = jQuery(query)
        }
        return store[query]
    }

    header() {
        return this.load("header")
    }
    header_content() {
        return this.load("header .content")
    }
    header_overlay() {
        return this.load("header .overlay")
    }
    main_menu() {
        `<nav id="main_menu"></nav>`
        return this.load("nav#main_menu")
    }
}

const elements = new Elements()

export default elements