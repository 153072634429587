//import popper from 'popper.js';
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/tab'
import './elements/scroller'
import {isMobile} from "mobile-device-detect";

import jQuery from "jquery"
import BackToTop from "../html/includes/BackToTop"

window.isMobile = isMobile

jQuery(document).ready(() => {
    BackToTop()
});
