const key = Symbol('store')

class Store {
    constructor() {
        this[key] = {}
    }

    /**
     * @param {string} name
     * @param {string|int} value
     */
    putInt(name, value) {
        this[key][name] = +value
    }

    /**
     * @param {string} name
     * @param {string|int} value
     */
    putString(name, value) {
        this[key][name] = value + ''
    }

    /**
     * @param {string} name
     * @return {number}
     */
    getInt(name) {
        return +this[key][name]
    }

    /**
     * @param {string} name
     * @return {string}
     */
    getString(name) {
        return this[key][name] + ''
    }

    /**
     * Получаем данные с CSS
     * @return {CSSStyleDeclaration}
     */
    getRootStyle() {
        return getComputedStyle(document.documentElement)
    }
}

const store = new Store();
export default store