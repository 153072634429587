import jQuery from "jquery";
import {isMobile} from "mobile-device-detect";
import elements from "./index";
import store from "../store/store";

if (isMobile) {
    elements.header().css('height', '30vh')
}
store.putInt(':root--main-indentation', parseInt(store.getRootStyle().getPropertyValue('--main-indentation')))

const resizeListener = () => {
    store.putInt('headerHeight', elements.header().height())
}
addEventListener("resize", resizeListener);
window.onresize = resizeListener
resizeListener();

window.requestAnimFrame = (function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

class Scroller {
    constructor() {
        this.latestKnownScrollY = 0;
        this.ticking = false;
    }

    _init_() {
        window.addEventListener('scroll', this.onScroll.bind(this), false);
        elements.header_overlay().css('background-image', jQuery('header:first-of-type').css('background-image'));
    }


    onScroll() {
        this.latestKnownScrollY = window.scrollY;
        this.onRequestTick();
    }


    onRequestTick() {
        if (!this.ticking) {
            window.requestAnimFrame(this.actionUpdate.bind(this));
        }
        this.ticking = true;
    }

    actionUpdate() {
        const currentScrollY = this.latestKnownScrollY;
        this.ticking = false;

        const slowScroll = currentScrollY / 2, blurScroll = currentScrollY * 2, opaScroll = 1.4 - currentScrollY / 200;
        elements.main_menu().css('position', currentScrollY > store.getInt('headerHeight') ? 'fixed' : 'absolute');

        const translateY = `translateY(${slowScroll}px)`
        elements.header_content().css({
            'transform': translateY,
            '-moz-transform': translateY,
            '-webkit-transform': translateY,
            'opacity': opaScroll
        });

        elements.header_overlay().css({
            'opacity': blurScroll / store.getInt('headerHeight')
        });
    }
}

new Scroller()._init_();